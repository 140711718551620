/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export default class FooterComponent extends React.Component {
  render() {
    return (
      <>
        {/* <div class="flex justify-center bg-gray-200">
          <div class="bg-gray-200 h-1 w-16 rounded"></div>
        </div> */}
    
        <footer class="bg-gray-100 py-8">
          <div class="flex flex-col-reverse md:flex-row md:items-center md:justify-between px-8">
            <small class="block text-sm text-gray-500">
              &copy; bayun.id
            </small>
            <div class="mb-4 md:mb-0">
              <div class="flex md:flex-row md:items-center text-center">
                <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/__bayungrh/" class="text-xl md:text-base leading-normal text-gray-700 hover:text-gray-900 no-underline mb-4 md:mb-0 md:ml-8 pr-4 lg:pr-0"><i className="fab text-1xl fa-instagram"></i></a>
                <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/bayun_12" class="text-xl md:text-base leading-normal text-gray-700 hover:text-gray-900 no-underline mb-4 md:mb-0 md:ml-8 pr-4 lg:pr-0"><i className="fab text-1xl fa-twitter"></i></a>
                <a rel="noopener noreferrer" target="_blank" href="https://github.com/bayungrh/" class="text-xl md:text-base leading-normal text-gray-700 hover:text-gray-900 no-underline mb-4 md:mb-0 md:ml-8 pr-4 lg:pr-0"><i className="fab text-1xl fa-github"></i></a>
                <a rel="noopener noreferrer" target="_blank" href="https://medium.com/@bayun" class="text-xl md:text-base leading-normal text-gray-700 hover:text-gray-900 no-underline mb-4 md:mb-0 md:ml-8 pr-4 lg:pr-0"><i className="fab text-1xl fa-medium"></i></a>
              </div>
            </div>
          </div>
        </footer>
      </>
    )
  }
}