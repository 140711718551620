import React from 'react';
import Header from '../Components/Header';
import Home from '../Components/Home';
import ProjectComponent from '../Components/Projects';
import Footer from '../Components/Footer';

class IndexPage extends React.Component {
  render() {
    return (
      <>
        <Header/>
        <Home/>
        <ProjectComponent/>
        <Footer/>
      </>
    )
  }
}

export default IndexPage;
