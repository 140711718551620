import React from 'react'
import foto from '../myimage-1.jpg';

export default class Home extends React.Component {
    render() {
      return (
        <>
          <section className="bg-gray-900 h-full py-8">
            <div className="w-10/12 mx-auto ml-auto mr-auto h-full">
              <div className="flex items-center justify-center text-center mx-auto h-full">
                <div>
                  <div className="flex items-center justify-center mb-5 mt-8">
                    <div className="relative w-40 h-40 xl:w-64 xl:h-64">
                      <img className="rounded-full w-40 h-40 xl:w-64 xl:h-64 border border-gray-900 shadow-sm" src={foto} alt="user" />
                    </div>
                  </div>
                  <h1 className="text-3xl lg:text-4xl font-semibold leading-none tracking-normal mb-1">Mochammad Bayu Nugraha</h1>
                  <h2 className="text-xl lg:text-2xl text-blue-200 opacity-75 font-normal leading-tighter mb-7">Software Engineer.</h2>
                  <p className="opacity-75 text-gray-100 text-normal lg:text-lg leading-tight mb-8">
                    Hi, I'm Bayu, 23 years old from Jakarta, Indonesia. <br/>Currently working at Jubelio from 2020 until now as a <br/><strong className="font-bold">Software Engineer Lead</strong>.
                    <br/><br/>I enjoy learning about new technologies.
                    <br/>For development I am experienced with NodeJS, Python and PHP.
                    <br/>I'm currently learning Go
                  </p>
                  {/* <div className="flex flex-col sm:flex-row items-center justify-center sm:pt-1 mb-3 sm:mb-6">
                    <a className="flex items-center text-xl leading-none text-blue-500 hover:text-blue-600 no-underline mt-2 sm:mt-0 sm:ml-2" href="#">
                      <div className="mr-3">
                        <svg className="align-middle" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"/><polyline points="8 12 12 16 16 12"/><line x1="12" y1="8" x2="12" y2="16"/></svg>
                      </div>
                      <span></span>
                    </a>
                    <a className="flex items-center text-xl leading-none text-blue-500 hover:text-blue-600 no-underline mt-2 sm:mt-0 sm:ml-2" href="#">
                      <div className="mr-3">
                          <svg className="align-middle" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"/><polyline points="8 12 12 16 16 12"/><line x1="12" y1="8" x2="12" y2="16"/></svg>
                      </div>
                      <span></span>
                    </a>
                  </div> */}
                  <div className="flex flex-col sm:flex-row justify-center pt-1">
                    <a href="https://github.com/bayungrh/" className="bg-transparent py-4 text-1xl leading-none text-blue-200 font-semibold hover:text-blue-400 h-12 px-8 border border-blue-200 hover:border-blue-400 rounded-full whitespace-no-wrap mb-2 sm:mt-0 sm:mr-2">
                      <i className="fab fa-github fa-fw"></i> Github
                    </a>
                    <a href="mailto:m.bayuu.nugraha@gmail.com" target="_blank" rel="noopener noreferrer" className="bg-blue-500 hover:bg-blue-600 py-4 text-1xl leading-none text-white font-semibold h-12 px-8 rounded-full whitespace-no-wrap mb-2 sm:mb-0 sm:mr-2">
                      <i class="fas fa-envelope-open-text fa-fw"></i> Reach me
                    </a>
                    <a href="https://www.linkedin.com/in/bayunugraha" className="bg-transparent py-4 text-1xl leading-none text-blue-200 font-semibold hover:text-blue-400 h-12 px-8 border border-blue-200 hover:border-blue-400 rounded-full whitespace-no-wrap mb-2 sm:mt-0">
                      <i class="fab fa-linkedin fa-fw"></i> Linkedin
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      );
    };
}
