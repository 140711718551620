import React from 'react';

class TimelineExperience extends React.Component {
  render() {
    return (
      <>
        <section class="dark:bg-gray-800 dark:text-gray-300">
          <div class="container max-w-5xl px-4 py-12 mx-auto">
            <div class="grid gap-4 mx-4 sm:grid-cols-12">
              <div class="col-span-12 sm:col-span-3">
                <div class="text-center sm:text-left mb-14 before:block before:w-24 before:h-3 before:mb-5 before:rounded-md before:mx-auto sm:before:mx-0 before:dark:bg-violet-400">
                  <h3 class="text-3xl font-semibold">Experience</h3>
                  <span class="text-sm font-bold tracki uppercase dark:text-gray-400"></span>
                </div>
              </div>
              <div class="relative col-span-12 px-4 space-y-6 sm:col-span-9">
                <div class="col-span-12 space-y-12 relative px-4 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:dark:bg-gray-700">
                  <div class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-violet-400">
                    <div class="flex items-center mb-1">
                        <div class="bg-indigo-600 rounded-full h-4 w-4 border-gray-200 border-2 z-10"></div>
                        <div class="flex-1 ml-4 text-lg">Jubelio - Lead Software Engineer (Squad Leader)</div>
                    </div>
                    <time class="text-xs ml-8 uppercase dark:text-gray-400">Aug 2023 - Now</time>
                    <div class="flex items-center mb-1 mt-5">
                        <div class="bg-indigo-600 rounded-full h-4 w-4 border-gray-200 border-2 z-10"></div>
                        <div class="flex-1 ml-4 text-lg">Jubelio - Software Engineer (Full-Stack)</div>
                    </div>
                    <time class="text-xs ml-8 uppercase dark:text-gray-400">Mar 2020 - Now</time>
                    <div className="mt-3 ml-8">
                        Responsibilities<br/>
                        <ul className="list-disc ml-3">
                          <li>Collaborate together in a squad (team) and deliver progress every day</li>
                          <li>Developing features to deliver business targets</li>
                          <li>Maintain and improve the responsiveness of an application that is already running</li>
                          <li>Responsible for completing the assigned task</li>
                        </ul>
                        <br/>Impact<br/>
                        <ul className="list-disc ml-3">
                          <li>Helps to reduce the number of bug report tickets</li>
                          <li>Improvise performance to improve the smoothness of an application</li>
                          <li>Code refractor with best practices to improve the flow of a code</li>
                        </ul>
                    </div>
                  </div>
                  <div class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-violet-400">
                    <div class="flex items-center mb-1">
                        <div class="bg-indigo-600 rounded-full h-4 w-4 border-gray-200 border-2 z-10">
                        </div>
                        <div class="flex-1 ml-4 text-lg">Triplogic - Back-end Engineer</div>
                    </div>
                    <time class="text-xs ml-8 uppercase dark:text-gray-400">Jun 2019 - Mar 2020</time>
                    <p class="mt-3 ml-8">
                      Develop Microservices & Core Backend Develop
                    </p>
                  </div>
                  <div class="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-violet-400">
                    <div class="flex items-center mb-1">
                        <div class="bg-indigo-600 rounded-full h-4 w-4 border-gray-200 border-2 z-10">
                        </div>
                        <div class="flex-1 ml-4 text-lg">PT. Integrated Synergy Systems - Back-end Developer</div>
                    </div>
                    <time class="text-xs ml-8 uppercase dark:text-gray-400">Sep 2018 - Apr 2019</time>
                    <div class="mt-3 ml-8">
                      <p>Develop and implements software components with design based on modular components and object oriented principles.</p>
                      <p className='mt-3'>Collaborate with team members and document the principles and frameworks that guide architecture decisions. Participates in peer/technical reviews.</p>
                      <p className='mt-3'>Develop and maintain backward compatibility APIs for several mobile and web applications</p>
                      <p className='mt-3'>Create, integrate and modify API to third-party partners ( e.g.: sms gateways )</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>  
    )
  }
}

export default TimelineExperience;
