import React from "react";
import projectJson from '../data/projects.json';
import TimelineExperience from "./TimelineExperience";

class ProjectComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      projects: projectJson
    }
  }

  render() {
    return (
      <>
        <TimelineExperience/>

        <section class="bg-gray-100 py-8 flex flex-col items-center">
          {/* <h1 className="text-3xl text-gray-500 text-left font-sans tracking-wider capitalize">My Project's</h1> */}
          <div class="p-10 p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5">
            { this.state.projects && this.state.projects.map((project) => {
              return (
                <div class="max-w-sm rounded overflow-hidden shadow-lg">
                  <img class="w-full h-64 object-scale-down" src={project.image} alt="itsdev" />
                  <div class="px-6 py-4">
                    <div class="font-bold text-xl mb-2 text-gray-500">{ project.title }</div>
                    <p class="text-gray-700 text-base mb-5">
                      { project.description }
                    </p>
                    <a href={project.link} target="_blank" rel="noopener noreferrer" className="bg-blue-500 hover:bg-blue-700 text-sm text-white font-bold py-1 px-4 rounded-full"><i className="fas fa-link"></i> VISIT</a>
                  </div>
                  <div class="px-6 pt-4 pb-2">
                    { project.tags.map((tag) => (<span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#{tag}</span>)) }
                  </div>
                </div>
              )
            }) }
          </div>
        </section>
      </>
    )
  }
}

export default ProjectComponent;
