import React from 'react';
import HomePage from './Pages/Index';
import LinkPage from './Pages/Links';
import {
  Switch,
  Route
} from "react-router-dom";

function App() {
  return (
    <>
      <Switch>
        <Route exact path="/" component={HomePage} />
        {/* <Route path="/about" component={About} /> */}
        <Route path="/links" component={LinkPage} />
        <Route component={HomePage} />
      </Switch>
    </>
  );
}

export default App;
