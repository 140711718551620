import React from "react";
import { Link } from 'react-router-dom'

const mediaLink = [
  {
    link: 'https://github.com/bayungrh',
    icon: 'fab fa-github',
    content: `Github`
  },
  {
    link: 'https://www.instagram.com/__bayungrh/',
    icon: 'fab fa-instagram',
    content: `Instagram`
  },
  {
    link: 'https://twitter.com/bayun_12',
    icon: 'fab fa-twitter',
    content: `Twitter`
  },
  {
    link: 'https://www.linkedin.com/in/bayunugraha/',
    icon: 'fab fa-linkedin',
    content: `LinkedIn`
  },
  {
    link: 'https://medium.com/@bayun',
    icon: 'fab fa-medium',
    content: `Medium`
  }
];

const worksLink = [
  {
    link: 'https://reqbox.dev',
    icon: '',
    content: '[WEB] ReqBox - the fastest way to capture webhook API requests.'
  },
  {
    link: 'https://rhsia.id',
    icon: '',
    content: `[WEB] rhsia.id - Kirim pesan rahasia ke rekan Anda!`
  },
  {
    link: 'https://langsung-wa.vercel.app',
    icon: '',
    content: `[WEB] langsung-wa - WhatsApp chat directly without saving contacts`
  },
  {
    link: 'https://itsdev.id',
    icon: '',
    content: '[WEB] itsdev.id - A free indentity for web developers'
  },
  {
    link: 'https://twitter-reply-pick.vercel.app',
    icon: '',
    content: '[WEB] Twitter Reply Picker'
  },
  {
    link: 'https://info-tabung-oksigen.vercel.app',
    icon: '',
    content: '[WEB] Pencarian Tabung Oksigen'
  },
  {
    link: 'https://github.com/bayungrh/rs-bed-availability-api',
    icon: '',
    content: '[API] Hospital Bed Availibility'
  },
  {
    link: 'https://twitter-broadcast-search.vercel.app',
    icon: '',
    content: '[Web] Twitter Broadcast Search'
  },
  {
    link: 'https://github.com/bayungrh/tokopedia-emas-notifier',
    icon: '',
    content: '[App] Tokopedia Emas Notifier'
  },
  {
    link: 'https://showroom48-api.vercel.app',
    icon: '',
    content: '[API] Showroom 48'
  },
  {
    link: 'https://github.com/bayungrh/covid19bot-indonesia',
    icon: '',
    content: '[BOT] Covid-19 Twitter Bot'
  }
];

export default class Links extends React.Component {
  render() {
    return (
      <>
        <div className="flex flex-col bg-gray-900 justify-center min-h-screen py-2 font-mono pb-20">
          <main className="flex flex-col items-center mt-20 flex-1">
            <Link className="text-3xl font-bold" to="/">
              bayun<span className="text-blue-400">.id</span>
            </Link>
            <div className="container mx-auto mt-10 items-center">
              <div className="relative pt-5 pb-6">
                <div className="container mx-auto px-4">
                  <div className="flex flex-wrap items-center md:justify-between text-center justify-left">
                    <h1>—— Social Media</h1>
                    { mediaLink.map((link) => (
                      <a target="_blank" rel="noopener noreferrer" href={link.link} className={`w-full my-2 py-5 text-base transition duration-300 ease select-none hover:text-white hover:bg-gray-500 focus:outline-none border border-gray-500 bg-transparent text-white`}>
                        <i className={link.icon}></i> {link.content}
                      </a>
                    )) }

                    <h1 className="mt-10">—— Work's</h1>
                    { worksLink.map((link) => (
                      <a target="_blank" rel="noopener noreferrer" href={link.link} className={`w-full my-2 py-5 text-sm transition duration-300 ease select-none hover:text-gray-100 hover:bg-gray-700 focus:outline-none focus:shadow-outline border border-gray-700 bg-transparent text-gray-200`}>
                        { link.content }
                      </a>
                    )) }

                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
    )
  }
}