/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {Link} from 'react-router-dom'

class HeaderComponent extends React.Component {
  render() {
    return (
      <>
        <header className="container absolute top-0 left-0 w-full py-4">
          <div className="flex items-center justify-between px-8">
            <span className="text-2xl tracking-tight font-semibold xl:ml-44">
              bayun<span className="text-blue-400">.id</span>
            </span>
            <div className="">
              <div className="flex items-center">
                {/* <Link className="text-lg leading-normal text-blue-200 hover:text-blue-400 no-underline ml-8" to="/">Home</Link> */}
                <Link className="text-lg leading-normal text-blue-200 hover:text-blue-400 no-underline ml-8" to="/links"><i className="fa fa-link fa-fw"></i> </Link>
              </div>
            </div>
          </div>
        </header>
      </>
    )
  }
}

export default HeaderComponent;